.Footer {
    width: '100%';
    display: flex;
    color: white;
    background-image: url('../../assets/Rectangle\ 8.png');
    background-size: cover;
    background-position: center;
    box-sizing: border-box; 
}

