
.headline{
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2%;
    font-size: 35px;
}
.title{
    font-size: 18px;
    margin-bottom: 2%;
}
.numbers{
    text-decoration: none;
    color: inherit; 
    font-weight: bold;
}
.contact{
    text-decoration: none;
    color: inherit;
}
.contact:hover{
    color:  rgb(41, 136, 224) ;
}
/* .contact:hover{
color:;
} */

.link{
font-size: 18px;
cursor: pointer;
font-size: 20px;
}
