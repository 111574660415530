body{
    background-color: #f1f2f2;
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
}

.home-page{
    min-height: 100vh;
}

.navbar {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    position: absolute;
    width: 100%;
}

.Navcontainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    z-index: 10;
    max-width: 1080px;
}

.transparentNavbar{
    background-color: transparent;
}

.defaultNavbar{
    background-image:linear-gradient(#612a68,#b32a60);
}

.nav-link{
    font-weight: bold;
    color: white;
}

.navbar-nav{
    flex-grow: 1!important;
    justify-content: center;
    gap: 30px;
}

.navbar-toggler:focus {
box-shadow: none;
}

.navbar ul{
    margin: auto;
}

.phoneNo{
    color: white;
    display: flex;
    flex-direction: row;
    width: max-content;
    align-items: center;
}



.mobileNavbar{
    background-color: white;
    color: black;
    z-index: 10;
}
.logo{
    width: 100px;
}
/* -----------------------Home Component-------------------------------- */
.container{
    position: relative;
    max-width: 1080px;
}

.Home_content{
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: visible;
}

.Home_headline{
    font-weight: bold;
    font-size: 45px;
}

    .Home_img{
        overflow:visible;
        width: 900px;
        height: 600px;
        z-index: 10;
    }

.contactBtn{
    border: 1px solid White;
    background-color: transparent;
    color: white;
    border-radius: 10px;
    padding: 1% 2%;
    text-decoration: none;
}

.contactBtn:hover{
    background-color: black;
    border: black;
}

.header, .header2{
    width: 100%;
    height: 40vh;
    background-image: url('./assets/young-freelance.webp');
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    border-bottom: 1px solid transparent;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom: 5%;
    z-index: -1;
    position: relative;
}

.header .Title, .header2 .Title{
    text-align: center;
    margin: auto;
    padding-top: 8%;
    color: white;
    text-transform: capitalize;
    font-weight: bold;
}

.header .Title ol , .header2 .Title ol{
    list-style-type: none;
    display: flex;
    justify-content: center;
}

.title2{
    text-transform: lowercase;
}

.header .Title .title2::before , .header2 .Title .title2::before{
content: "/ " ;
color:white;
}

.header .Title ol li, .header2 .Title ol li{
    margin-right: 7px;
}

.divider{
    width: 100%;
    height: 1px;
    background-color: rgb(163, 160, 160);
    margin: 2% 0;
}

.divider2{
    width: 100%;
    height: 1px;
    background-color: white;
}

.services-page{
    min-height: 50vh;
    /* padding-top: 5%; */
}
@media screen and (min-width: 992px){
    .slick-list{
        width: 85%;
        margin: auto;
    }
}

@media screen and (max-width: 992px) {
    .header2 .Title, .header .Title{
        padding-top: 9%
    }
   
    .navbar{
        background-color: white;
        color: black;
        top: 0;
    }   
    .phoneNo{
        color: black ;            
        margin-top: 2%;
        margin-left: 3%;

    }
    .navbar-nav {
        gap: 10px;
    }
    .Navcontainer{
        max-width: 960px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
      .nav-item {
        margin: 0 1%; 
      }

    .nav-link{
        color: black;
        margin-left: 2%;
    }
     .caption {
        padding-top: 7%; 
        margin: 0;
    }
    .Home_content{
        justify-content: center;
       margin: 3%;
    }
    .Home_img{
        width: 500px;
        height: 300px;
    }
    .slick-list{
        width: 85%;
        margin: auto;
    }
}

@media screen and (max-width: 768px) {
    .caption {
        margin-top: 8%; 
    }
    .header2 .Title, .header .Title{
        padding-top: 17%;
    }

    .Home_content{
        flex-direction: column;
    }
    .Home_img{
        width: 600px;
        height: 350px;
    }   
}

@media screen and (max-width: 600px) {
    .header2 .Title, .header .Title{
        padding-top: 20%;
    }
    .caption{
        margin-top: 16%;
    }
    
    .header , .header2{
        height: 35vh;
    }
    .container{
    padding: 0 5%
    }

    .Home_content{
        flex-direction: column;
        margin:auto
    }
    .Home_img{
        width: 350px;
        height: 250px;
    }
    .next-arrow , .prev-arrow {
        width: 25px;
    }
    
    .slick-list{
        width: 70%;
        margin: auto;
    }
}

@media screen and (max-width: 450px) {
    .header , .header2{
        height: 30vh;
    }
    .header2 .Title, .header .Title{
        padding-top: 26%;
    }
}

/* -------------------OurClients------------ */

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    margin-right: 10px;
    margin-left: 10px;
}

.next-arrow , .prev-arrow{
    background-image: linear-gradient(#612a68,#b32a60);
    position: absolute;
    cursor: pointer;
    z-index: 1;
    transform: translate(0, -50%);
    border-radius: 50px;
    padding: .5%;
    top: 50%;
    width: 45px;
}

.next-arrow {
    right: 0;
}

.prev-arrow {
    left: 0;
}

/* ------------------Animation------------------- */
.area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }
  
.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(223, 168, 168, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 10s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 13s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 10s;
    animation-duration: 11s;
}
.circles li:nth-child(11){
    left: 20%;
    width: 120px;
    height: 120px;
    animation-delay: 15s;
    animation-duration: 11s;
}

.circles li:nth-child(12){
    left: 70%;
    width: 15px;
    height: 15px;
    animation-delay: 6s;
    animation-duration: 35s;
}

.circles li:nth-child(13){
    left: 5%;
    width: 100px;
    height: 110px;
    animation-delay: 3s;
    animation-duration: 11s;
}

.circles li:nth-child(14){
    left: 35%;
    width: 30px;
    height: 35px;
    animation-delay: 6s;
    animation-duration: 35s;
}

@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
 /* -------------Loading-------------- */

 .Loading{
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 9999;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
 }

